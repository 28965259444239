@if (item.items && item.visible !== false) {
<a mat-list-item (mousedown)="expanded = !expanded" role="none"
  class="h-3rem flex align-items-center justify-content-start mdc-list-item">
  <span [matBadge]="item.badge" [matBadgeHidden]="item.badge === 0" matBadgePosition="above after"
    class="menu-item-icon" *ngIf="item.icon">
    <fa-icon [icon]="item.icon"></fa-icon>
  </span>
  <span class="ml-3 mr-1 menu-item-content">{{ item.label }}</span>
  <span class="menu-item-icon">
    <fa-icon size="xs" [icon]="expanded ? chevronUp : chevronDown"></fa-icon>
  </span>
</a>
@if(expanded) {
<div class="ml-4">
  @for (internal of item.items; track $index) {
  <app-menu-item [item]="internal"></app-menu-item>
  }
</div>
} } @else {
<a mat-list-item *ngIf="item.visible !== false" [routerLink]="item.routerLink ? item.routerLink : null"
  (mousedown)="doCommand()">
  <div class="h-3rem flex align-items-center justify-content-start">
    <span [matBadge]="item.badge" [matBadgeHidden]="item.badge === 0" matBadgePosition="above after"
      class="menu-item-icon" *ngIf="item.icon">
      <fa-icon [icon]="item.icon"></fa-icon>
    </span>
    <span class="ml-3 menu-item-content">{{ item.label }}</span>
  </div>
</a>
}